$(function () {

  $('.header__menu-toggle').on('click', function () {
    $('.header__menu').toggleClass('--open');
  });

  $(function () {
    $('.js-openChipMenu').on('click', function (e) {
      // e.preventDefault()
      $('.js-chipMenuInner').toggleClass('--open')
    })

  })

  $(function () {
    var swiperWork = new Swiper('.swiper-work', {
      slidesPerView: 'auto',
      autoHeight: true,
      centeredSlides: true,
      spaceBetween: 30,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      }
    });
    console.log(swiperWork);
  });
});